import up from 'unpoly';

import { $, $$ } from '../../../utils/dom';

const dcn = (suffix = '') => `.view-templates-PageQuiz${suffix}`;

up.compiler(dcn(), ($el) => {
    const resultIdToUrl = JSON.parse($el.dataset.resultIdToUrl);
    const answerIdToResultIdToWeight = JSON.parse(
        $el.dataset.answerIdToResultIdToWeight,
    );

    const $form = $(dcn('__form'), $el);
    const $progress = $(dcn('__progress'), $el);
    const $questions = $$(dcn('__question'), $el);

    const handlePageshow = () => {
        $progress.style.width = '1%';
        $form.reset();

        $questions[0].classList.remove('-z-1');
        $questions[0].classList.remove('opacity-0');

        $questions.slice(1).forEach(($question) => {
            $question.classList.add('-z-1');
            $question.classList.add('opacity-0');
        });
    };

    const handleChange = (event) => {
        const $oldQuestion = event.target.closest(dcn('__question'));

        const i = $questions.indexOf($oldQuestion);

        const $nextQuestion = $questions[i + 1];

        $progress.style.width = `${((i + 1) / $questions.length) * 100}%`;

        $oldQuestion.classList.add('-z-1');
        $oldQuestion.classList.add('opacity-0');

        $nextQuestion?.classList.remove('-z-1');
        $nextQuestion?.classList.remove('opacity-0');

        if ($nextQuestion) {
            return;
        }

        const formData = new FormData($form);

        const resultIdToScore = {};

        Array.from(formData.entries()).forEach(([_questionId, answerId]) => {
            const resultIdToWeight = answerIdToResultIdToWeight[answerId];

            Object.entries(resultIdToWeight).forEach(([resultId, weight]) => {
                resultIdToScore[resultId] =
                    (resultIdToScore[resultId] || 0) + weight;
            });
        });

        let winningResultId = null;
        let winningScore = -Infinity;

        Object.entries(resultIdToScore).forEach(([resultId, score]) => {
            if (score < winningScore) {
                return;
            }

            winningResultId = resultId;
            winningScore = score;
        });

        const winningUrl = resultIdToUrl[winningResultId];

        setTimeout(() => {
            window.location = winningUrl;
        }, 1000);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    window.addEventListener('pageshow', handlePageshow);
    $form.addEventListener('change', handleChange);
    $form.addEventListener('submit', handleSubmit);
});
