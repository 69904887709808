import up from 'unpoly';

import { $, $$ } from '../../../../utils/dom';
import toggleBodyLock from '../../../../utils/lockBodyScroll';

const dcn = (suffix = '') => `.view-components-blocks-CourseFilter${suffix}`;

class Filter {
    #courseIdToNameToValues;

    #activeFilters = [];

    #activeNames = [];

    constructor(courseIdToNameToValues) {
        this.#courseIdToNameToValues = courseIdToNameToValues;
    }

    // Mutators

    #updateActiveNames() {
        let activeNames = this.#activeFilters.map(([n]) => n);
        activeNames = new Set(activeNames);
        activeNames = Array.from(activeNames.values());

        this.#activeNames = activeNames;
    }

    activateFilter(name, value) {
        this.deactivateFilter(name, value);
        this.#activeFilters.push([name, value]);

        this.#updateActiveNames();
    }

    deactivateFilter(name, value) {
        this.#activeFilters = this.#activeFilters.filter(
            ([n, v]) => !(name === n && value === v),
        );

        this.#updateActiveNames();
    }

    clear() {
        this.#activeFilters = [];
        this.#activeNames = [];
    }

    updateFiltersFromForm($form) {
        const formData = new FormData($form);

        const activeFilters = [];

        Array.from(formData.entries()).forEach(([key, value]) => {
            if (key.endsWith('[]')) {
                key = key.slice(0, -2);
            }

            activeFilters.push([key, value]);
        });

        this.#activeFilters = activeFilters;

        this.#updateActiveNames();
    }

    // Accessors

    get hasActive() {
        return this.#activeFilters.length !== 0;
    }

    get activeNames() {
        return this.#activeNames;
    }

    isActive(name, value) {
        return !!this.#activeFilters.find(
            ([n, v]) => name === n && value.toString() === v,
        );
    }

    getFilterCount(name) {
        let count = 0;

        this.#activeFilters.forEach(([n]) => {
            if (n === name) {
                count += 1;
            }
        });

        return count;
    }

    courseMatches(courseId) {
        const nameToValues = this.#courseIdToNameToValues[courseId];

        return this.#activeNames.every((name) => {
            const values = nameToValues[name];

            if (values.length === 1 && values[0] === '*') {
                return true;
            }

            return values.some(
                (value) =>
                    !!this.#activeFilters.find(
                        ([n, v]) => name === n && value.toString() === v,
                    ),
            );
        });
    }

    inputIsEnabled(name, value) {
        if (this.isActive(name, value)) {
            return true;
        }

        const prospectiveFilter = this.clone();

        prospectiveFilter.#activeFilters =
            prospectiveFilter.#activeFilters.filter(([n]) => name !== n);
        prospectiveFilter.#updateActiveNames();

        prospectiveFilter.activateFilter(name, value);

        return Object.keys(this.#courseIdToNameToValues).some((courseId) =>
            prospectiveFilter.courseMatches(courseId),
        );
    }

    clone() {
        const clone = new Filter(this.#courseIdToNameToValues);
        clone.#activeNames = this.#activeNames;
        clone.#activeFilters = this.#activeFilters;

        return clone;
    }
}

up.compiler(dcn(), ($el) => {
    // Elements

    const $stickyContainer = $(dcn('-stickyContainer'), $el);
    const $sideFormContainer = $(dcn('-sideFormContainer'), $el);
    const $sideForm = $(dcn('-sideForm'), $el);
    const $sideClearButton = $(dcn('-sideClearButton'), $el);
    const $inputs = $$(dcn('-input'), $el);
    const $inputButtons = $$(dcn('-inputButton'), $el);
    const $inputCounts = $$(dcn('-inputCount'), $el);
    const $pillsContainer = $(dcn('-pillsContainer'), $el);
    const $pills = $$(dcn('-pill'), $el);
    const $pillButtons = $$(dcn('-pillButton'), $el);
    const $stickyCoursesContainer = $(dcn('-stickyCoursesContainer'), $el);
    const $coursesContainer = $(dcn('-coursesContainer'), $el);
    const $courses = $$(dcn('-course'), $coursesContainer);
    const $noMatchesMessage = $(dcn('-noMatchesMessage'), $el);
    const $openOffCanvasFormButton = $(dcn('-openOffCanvasFormButton'), $el);
    const $offCanvasFormContainer = $(dcn('-offCanvasFormContainer'), $el);
    const $offCanvasForm = $(dcn('-offCanvasForm'), $el);
    const $offCanvasCloseButton = $(dcn('-offCanvasCloseButton'), $el);
    const $offCanvasClearButton = $(dcn('-offCanvasClearButton'), $el);

    // Data

    const courseIdToNameToValues = {};

    $courses.forEach(($course) => {
        const { id } = $course.dataset;
        const nameToValues = JSON.parse($course.dataset.nameToValues);

        courseIdToNameToValues[id] = nameToValues;
    });

    // State

    let $currentPopover = null;

    const filter = new Filter(courseIdToNameToValues);

    let offCanvasFormIsOpen = false;
    let filterChangedWhileOffCanvasFormWasOpen = false;

    let oldElTop = $el.getBoundingClientRect().top;

    // DOM Mutators

    const updateInputCounts = () => {
        $inputCounts.forEach(($inputCount) => {
            $inputCount.innerText =
                filter.getFilterCount($inputCount.dataset.name) || '';
        });
    };

    const updateInputs = () => {
        $inputs.forEach(($input) => {
            let { name } = $input;
            const { value } = $input;

            if (name.endsWith('[]')) {
                name = name.slice(0, -2);
            }

            $input.checked = filter.isActive(name, value);
            $input.disabled = !filter.inputIsEnabled(name, value);
        });
    };

    const updateSideClearButton = () => {
        if (!$sideClearButton) {
            return;
        }

        $sideClearButton.disabled = !filter.hasActive;
    };

    const updateOffCanvasClearButton = () => {
        if (!$offCanvasClearButton) {
            return;
        }

        $offCanvasClearButton.disabled = !filter.hasActive;
    };

    const updatePills = () => {
        $pillsContainer?.classList.toggle(
            $pillsContainer.dataset.hiddenClass,
            !filter.hasActive,
        );

        $pills.forEach(($pill) => {
            const { name, value } = $pill.dataset;

            $pill.classList.toggle('hidden', !filter.isActive(name, value));
        });
    };

    const updateCourses = () => {
        let hasMatches = false;

        $courses.forEach(($course) => {
            const courseMatches = filter.courseMatches($course.dataset.id);

            $course.classList.toggle('hidden', !courseMatches);

            hasMatches = hasMatches || courseMatches;
        });

        $stickyCoursesContainer?.classList.toggle('hidden', filter.hasActive);
        $coursesContainer.classList.toggle('hidden', !hasMatches);
        $noMatchesMessage.classList.toggle('hidden', hasMatches);
    };

    const updateUi = () => {
        updateInputCounts();
        updateInputs();
        updateSideClearButton();
        updateOffCanvasClearButton();
        updatePills();
        updateCourses();
    };

    const scrollToTop = () => {
        const bodyTop = document.body.getBoundingClientRect().top;
        const coursesTop = $coursesContainer.getBoundingClientRect().top;
        const stickyContainerTop = parseInt(
            getComputedStyle($stickyContainer).getPropertyValue('top'),
            10,
        );
        const stickyContainerHeight =
            $stickyContainer.getBoundingClientRect().height;
        const stickyContainerBottom =
            stickyContainerTop + stickyContainerHeight;
        const gap = parseInt(
            getComputedStyle($coursesContainer).getPropertyValue('row-gap'),
            10,
        );

        const top = coursesTop - bodyTop - stickyContainerBottom - gap;

        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    };

    const toggleOffCanvasForm = (isOpen) => {
        if (offCanvasFormIsOpen === isOpen) {
            return;
        }

        $offCanvasFormContainer.classList.toggle(
            'pointer-events-none',
            !isOpen,
        );
        $offCanvasFormContainer.classList.toggle('bg-black/50', isOpen);

        $offCanvasForm.classList.toggle('translate-x-full', !isOpen);

        offCanvasFormIsOpen = isOpen;

        toggleBodyLock(isOpen);

        if (isOpen) {
            filterChangedWhileOffCanvasFormWasOpen = false;
        } else if (filterChangedWhileOffCanvasFormWasOpen) {
            filter.updateFiltersFromForm($offCanvasForm);

            updateUi();
            scrollToTop();
        }
    };

    const updateSideFormContainerScroll = () => {
        const { top: elTop, bottom: elBottom } = $el.getBoundingClientRect();

        if (elTop === oldElTop) {
            return;
        }

        const { top, bottom } = $sideFormContainer.getBoundingClientRect();
        const { offsetHeight, scrollTop, scrollHeight } = $sideFormContainer;

        const topDiff = Math.max(0, top - elTop);

        if (topDiff < scrollTop) {
            $sideFormContainer.scrollTop = topDiff;
        }

        const overflowHeight = scrollHeight - offsetHeight - scrollTop;

        const bottomDiff = Math.max(0, bottom + overflowHeight - elBottom);

        if (bottomDiff > 0) {
            $sideFormContainer.scrollTop = scrollTop + bottomDiff;
        }

        oldElTop = elTop;
    };

    // Event Handlers

    const handleOpenOffCanvasFormButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        toggleOffCanvasForm(true);
    };

    const handleOffCanvasCloseButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        toggleOffCanvasForm(false);
    };

    const handlePillButtonClick = (event) => {
        const $pill = event.currentTarget.closest(dcn('-pill'));

        const { name, value } = $pill.dataset;

        filter.deactivateFilter(name, value);

        updateUi();
        scrollToTop();
    };

    const handleSideClearButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        filter.clear();

        updateUi();
    };

    const handleSideFormChange = () => {
        filter.updateFiltersFromForm($sideForm);

        if ($currentPopover) {
            $currentPopover.classList.add('opacity-0');
            $currentPopover.classList.add('pointer-events-none');

            $currentPopover = null;
        }

        updateUi();
    };

    const handleOffCanvasFormChange = () => {
        filterChangedWhileOffCanvasFormWasOpen = true;

        filter.updateFiltersFromForm($offCanvasForm);

        updateInputs();
        updateOffCanvasClearButton();
    };

    const handleOffCanvasClearButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        filter.clear();
        filterChangedWhileOffCanvasFormWasOpen = false;

        toggleOffCanvasForm(false);
        updateUi();
        scrollToTop();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const handleInputButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const $button = event.currentTarget;

        const $popover = $button.parentElement.querySelector(
            dcn('-inputPopover'),
        );

        if ($popover === $currentPopover) {
            return;
        }

        if ($currentPopover) {
            $currentPopover.classList.add('opacity-0');
            $currentPopover.classList.add('pointer-events-none');
        }

        $popover.classList.remove('opacity-0');
        $popover.classList.remove('pointer-events-none');

        $currentPopover = $popover;
    };

    const handleBodyClick = (event) => {
        // Popover

        if ($currentPopover && !$currentPopover.contains(event.target)) {
            $currentPopover.classList.add('opacity-0');
            $currentPopover.classList.add('pointer-events-none');

            $currentPopover = null;
        }

        // Off Canvas Form

        if (offCanvasFormIsOpen && !$offCanvasForm.contains(event.target)) {
            toggleOffCanvasForm(false);
        }
    };

    const handleAnimationFrame = () => {
        updateSideFormContainerScroll();

        requestAnimationFrame(handleAnimationFrame);
    };

    // Setup Event Handlers

    $openOffCanvasFormButton?.addEventListener(
        'click',
        handleOpenOffCanvasFormButtonClick,
    );

    $offCanvasCloseButton?.addEventListener(
        'click',
        handleOffCanvasCloseButtonClick,
    );

    $offCanvasClearButton?.addEventListener(
        'click',
        handleOffCanvasClearButtonClick,
    );

    $sideClearButton?.addEventListener('click', handleSideClearButtonClick);
    $sideForm?.addEventListener('change', handleSideFormChange);
    $sideForm?.addEventListener('submit', handleSubmit);

    $offCanvasForm?.addEventListener('change', handleOffCanvasFormChange);
    $offCanvasForm?.addEventListener('submit', handleSubmit);

    $inputButtons.forEach(($button) => {
        $button.addEventListener('click', handleInputButtonClick);
    });

    $pillButtons.forEach(($pillButton) => {
        $pillButton.addEventListener('click', handlePillButtonClick);
    });

    document.body.addEventListener('click', handleBodyClick);

    if ($sideFormContainer) {
        requestAnimationFrame(handleAnimationFrame);
    }

    // Restore State on Load

    const restoreState = () => {
        filter.updateFiltersFromForm($offCanvasForm);
        updateUi();
    };

    if (document.readyState === 'complete') {
        restoreState();
    } else {
        window.addEventListener('load', () => {
            setTimeout(restoreState);
        });
    }
});
