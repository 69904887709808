function sendGaEvent(event) {
    const { category, action, label, value } = event;

    window.ga?.getAll()[0]?.tracker?.send('event', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
    });
}

function pushToDataLayer(event) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(event);
}

function gtm(Alpine) {
    Alpine.magic('ga', () => sendGaEvent);
    Alpine.magic('dataLayer', () => pushToDataLayer);
}

export default gtm;
