// import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import up from 'unpoly';

import { $, $$ } from '../../../../utils/dom';

const scrollIntoView = ($el, options) => {
    $el.scrollIntoView(options);

    return new Promise((resolve) => {
        const doSomething = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.target === $el && entry.intersectionRatio >= 0.9) {
                    observer.disconnect();
                    resolve();
                }
            });
        };

        const observer = new IntersectionObserver(doSomething, {
            root: null,
            rootMargin: '0px',
            threshold: 0.9,
        });

        observer.observe($el);
    });
};

const dcn = (suffix = '') => `.view-components-misc-RevealButton${suffix}`;

up.compiler(dcn(), ($el) => {
    const $buttonWrapper = $(dcn('-buttonWrapper'), $el);
    const $button = $(dcn('-button'), $el);
    const $contentWrapper = $(dcn('-contentWrapper'), $el);
    const $input = $$('input', $contentWrapper).find(
        ($i) => $i.type !== 'hidden' && !$i.disabled,
    );

    const revealEventName = $el.dataset.revealEvent;
    const shouldScrollIntoView = $el.dataset.scrollIntoView !== undefined;

    let isRevealing = false;
    let isRevealed = false;

    const handleRevealEvent = async (event) => {
        event.preventDefault();

        if (isRevealing) {
            return;
        }

        isRevealing = true;

        if (shouldScrollIntoView) {
            await scrollIntoView($contentWrapper, {
                behavior: 'smooth',
                block: 'center',
            });
        }

        isRevealing = false;

        if (!isRevealed) {
            isRevealed = true;

            $buttonWrapper.classList.add(
                'absolute',
                'left-0',
                'right-0',
                'top-0',
                'opacity-0',
            );

            $contentWrapper.classList.remove(
                'absolute',
                'left-0',
                'top-0',
                'w-full',
                'pointer-events-none',
            );
            $contentWrapper.classList.add('opacity-100');

            setTimeout(() => $input?.focus());
        } else {
            $input?.focus();
        }
    };

    $button.addEventListener('click', handleRevealEvent);

    if (revealEventName) {
        window.addEventListener(revealEventName, handleRevealEvent);
    }
});
