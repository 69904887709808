const hashPrefix = '#imbox-form-';

const handleAnchorClick = (event) => {
    const $a = event.target;

    if ($a.tagName !== 'A') {
        return;
    }

    if (!$a.hash.startsWith(hashPrefix)) {
        return;
    }

    const currentUrl = window.location.href.split('#')[0];
    const anchorUrl = $a.href.split('#')[0];

    if (currentUrl !== anchorUrl) {
        return;
    }

    event.preventDefault();

    const formId = $a.hash.slice(hashPrefix.length);

    // eslint-disable-next-line no-underscore-dangle
    window._imbox.push(['openForm', formId]);
};

document.addEventListener('click', handleAnchorClick);
