import toggleBodyLock from '../utils/lockBodyScroll';

function xNoscroll(Alpine) {
    Alpine.directive(
        'noscroll',
        Alpine.skipDuringClone(
            (el, { expression }, { effect, evaluateLater }) => {
                const evaluator = evaluateLater(expression);

                let oldValue = false;

                let undoDisableScrolling = () => {};

                effect(() =>
                    evaluator((value) => {
                        if (oldValue === value) return;

                        // Start noscroll.
                        if (value && !oldValue) {
                            setTimeout(() => {
                                toggleBodyLock(true);

                                undoDisableScrolling = () => {
                                    toggleBodyLock(false);
                                };
                            });
                        }

                        // Stop noscroll.
                        if (!value && oldValue) {
                            undoDisableScrolling();
                            undoDisableScrolling = () => {};
                        }

                        oldValue = !!value;
                    }),
                );
            },
        ),
    );
}

export default xNoscroll;
