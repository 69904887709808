import up from 'unpoly';

import { $, svgEl } from '../../../utils/dom';

const cn = (suffix = '') => `wp-blocks-berghs-animated-banner${suffix}`;
const dcn = (suffix = '') => `.${cn(suffix)}`;

const NUM_STRIPES = 10;
const SQRT_2 = Math.sqrt(2);
const DURATION = 3;

function ease(t, b, c, d) {
    t /= d / 2;

    if (t < 1) return (c / 2) * t ** 4 + b;

    t -= 2;

    return (-c / 2) * (t ** 4 - 2) + b;
}

const setAtts = ($el, atts) => {
    Object.entries(atts).forEach(([name, value]) => {
        $el.setAttribute(name, value);
    });
};

up.compiler(dcn(), ($el) => {
    const $svg = $(dcn('__svg'), $el);
    const stripes = [];

    for (let i = 0; i < NUM_STRIPES; i += 1) {
        const $stripe = svgEl('line');

        $svg.appendChild($stripe);

        const stripe = { $stripe, k: 0 };

        stripes.push(stripe);
    }

    const animate = (t) => {
        const rt = t % (2 * DURATION);

        stripes.forEach((stripe, i) => {
            const n = i % 4;

            let k1;
            let k2;
            let position;

            if (n === 0) {
                k1 = -1;
                k2 = 1;
                position = 0;
            } else if (n === 1) {
                k1 = 1;
                k2 = -1;
                position = DURATION;
            } else if (n === 2) {
                k1 = 1;
                k2 = -1;
                position = 0;
            } else if (n === 3) {
                k1 = -1;
                k2 = 1;
                position = DURATION;
            }

            let k = -1;

            if (rt > position && rt <= position + DURATION) {
                k = ease(rt - position, k1, k2 - k1, DURATION);
            }

            stripe.k = k;
        });
    };

    const layout = () => {
        const { width, height } = $svg.getBoundingClientRect();

        const step = width / (NUM_STRIPES - 1);
        const strokeWidth = step / SQRT_2;
        const dx = height / 2 + strokeWidth / 2;
        const dy = strokeWidth / 2;

        stripes.forEach((stripe, i) => {
            const { $stripe, k } = stripe;

            const x = step * i;

            let x1 = x - dx;
            let y1 = height + dy;
            let x2 = x + dx;
            let y2 = -dy;

            if (k < 0) {
                x2 = x1 + (x2 - x1) * (1 + k);
                y2 = y1 + (y2 - y1) * (1 + k);
            } else {
                x1 = x2 - (x2 - x1) * (1 - k);
                y1 = y2 - (y2 - y1) * (1 - k);
            }

            setAtts($stripe, {
                'stroke-width': strokeWidth,
                x1,
                y1,
                x2,
                y2,
            });
        });
    };

    let t0;
    const handleAnimationFrame = (timestamp) => {
        if (!t0) {
            t0 = timestamp;
        }

        const t = (timestamp - t0) / 1000;

        animate(t);
        layout();

        requestAnimationFrame(handleAnimationFrame);
    };
    requestAnimationFrame(handleAnimationFrame);
});
