import up from 'unpoly';
import { $, $$ } from '../../../../utils/dom';
import formSubmitHandler from '../../../form-submit-handlers';

const dcn = (suffix = '') =>
    `.view-components-blocks-NewsletterSignup${suffix}`;

up.compiler(dcn(), ($el) => {
    formSubmitHandler({
        handler: 'pardot',
        $form: $(dcn('__form'), $el),
        $inputs: $$(dcn('__input'), $el),
        $submit: $(dcn('__submit'), $el),
        $message: $(dcn('__message'), $el),
        options: $el.dataset,
    });
});
