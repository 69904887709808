import Alpine from 'alpinejs';

import {
    deleteFile,
    getS3Params,
    uploadFile,
} from '../../../../utils/application-files';

const ApplicationFileUploadControl = ({ applicationId, group, name }) => ({
    name,
    docs: [],
    nextId: 1,

    init() {
        this.handleSubmit = this.handleSubmit.bind(this);

        this.addMiddleware(this.handleSubmit);
    },
    destroy() {
        this.removeMiddleware(this.handleSubmit);
    },

    onAddClick(event) {
        if (this.isDisabled) {
            event.preventDefault();
            event.stopPropagation();
        }
    },
    onFileInputChange() {
        const file = this.$refs.fileInput.files[0];

        this.$refs.fileInput.value = null;

        if (!file || this.isDisabled) {
            return;
        }

        this.addFile(file);
    },

    async addFile(file) {
        const id = this.nextId;
        this.nextId += 1;

        try {
            const { method, key, url, headers } = await getS3Params(
                applicationId,
                group,
                file,
            );

            const abortController = new AbortController();

            const newDoc = {
                id,
                file,
                method,
                key,
                url,
                headers,
                abortController,
                isDone: false,
                progress: 0,
            };

            this.setDocs([...this.docs, newDoc]);
        } catch (err) {
            // eslint-disable-next-line no-alert
            alert(err.message);

            this.deleteDoc(id);
        }
    },

    async deleteDoc(id) {
        const doc = this.docs.find((f) => f.id === id);

        try {
            doc.abortController.abort();

            this.setDocs(this.docs.filter((f) => f.id !== id));

            await deleteFile(doc.key);
        } catch (err) {
            // eslint-disable-next-line no-alert
            alert(err.mesage);
        }
    },

    updateDoc(id, nextProps) {
        this.setDocs(
            this.docs.map((file) => {
                if (file.id !== id) {
                    return file;
                }

                return {
                    ...file,
                    ...nextProps,
                };
            }),
        );
    },

    setDocs(docs) {
        this.docs = docs;

        this.$nextTick(() => {
            this.form.touch(name);
            docs.forEach((_, i) => {
                this.form.touch(`${name}.${i}`);
            });
            this.form.validate();
        });
    },

    async handleSubmit() {
        // eslint-disable-next-line no-restricted-syntax
        for (const doc of this.docs) {
            if (doc.isDone) {
                // eslint-disable-next-line no-continue
                continue;
            }

            const { id, file, url, method, headers, abortController } = doc;

            try {
                // eslint-disable-next-line no-await-in-loop
                await uploadFile({
                    file,
                    url,
                    method,
                    headers,
                    applicationId,
                    group,
                    abortSignal: abortController.signal,
                    onProgress: (progress) => {
                        this.updateDoc(id, {
                            progress,
                        });
                    },
                });
            } catch (err) {
                this.updateDoc(id, {
                    isDone: false,
                    progress: 0,
                });

                throw err;
            }

            this.updateDoc(id, {
                isDone: true,
                progress: 0,
            });
        }
    },
});

Alpine.data(
    'app_view_components_applications_ApplicationFileUploadControl',
    ApplicationFileUploadControl,
);
