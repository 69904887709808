import { $onload } from '../../../utils/dom';

$onload('.wp-block-berghs-lottie-animation', ($container) => {
    import('lottie-web').then((lottie) => {
        const { json } = $container.dataset;
        const animationData = JSON.parse(json);

        $container.innerHTML = '';

        lottie.loadAnimation({
            container: $container,
            loop: true,
            autoplay: true,
            animationData,
        });
    });
});
