import up from 'unpoly';

import { $, $$ } from '../../../utils/dom';

const cn = (suffix = '') => `wp-blocks-berghs-countdown${suffix}`;
const dcn = (suffix = '') => `.${cn(suffix)}`;

up.compiler(dcn(), ($el) => {
    const end = new Date($el.dataset.endDate).getTime();

    const parts = $$(dcn('__part'), $el).map(($part) => [
        $part,
        $(dcn('__part-value'), $part),
        parseInt($part.dataset.duration, 10) * 1000,
        $part.dataset.pad === '1',
    ]);

    const update = () => {
        let diff = end - Date.now();

        if (diff < 0) {
            diff = 0;
        }

        parts.forEach(([_$part, $value, duration, pad]) => {
            const value = Math.floor(diff / duration);
            diff -= value * duration;

            const text = `${value}`.padStart(pad ? 2 : 0, '0');

            if ($value.innerText === text) {
                return;
            }

            $value.innerText = text;
        });
    };

    update();
    parts.forEach((p) => p[1].classList.remove('opacity-0'));

    setInterval(update, 100);
});
