import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';

import tracking from './tracking';
import xNoscroll from './x-noscroll';

Alpine.plugin(focus);
Alpine.plugin(xNoscroll);
Alpine.plugin(tracking);

export default Alpine;
