export function $(sel, node = null) {
    return (node || document).querySelector(sel);
}

export function $$(sel, node = null) {
    return Array.from((node || document).querySelectorAll(sel));
}

export function $onload(sel, fn) {
    const loader = () =>
        $$(sel).forEach(($el) =>
            fn($el, {
                cn: (suffix = '') => `${sel}${suffix}`,
                $: (suffix, $parent = $el) => $(`${sel}${suffix}`, $parent),
                $$: (suffix, $parent = $el) => $$(`${sel}${suffix}`, $parent),
            }),
        );

    if (document.readyState === 'complete') {
        queueMicrotask(loader);
    } else {
        window.addEventListener('load', () => queueMicrotask(loader));
    }
}

export function svgEl(tagname, atts = {}, ...children) {
    const el = document.createElementNS('http://www.w3.org/2000/svg', tagname);

    Object.entries(atts).forEach(([name, value]) => {
        el.setAttribute(name, value);
    });

    children.forEach((child) => {
        el.appendChild(child);
    });

    return el;
}

export function elFromHtml(html) {
    const div = document.createElement('div');
    div.innerHTML = html.trim();

    return div.firstChild;
}
