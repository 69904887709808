import up from 'unpoly';

import { $, $$ } from '../../../../utils/dom';
import formSubmitHandler from '../../../form-submit-handlers';

const dcn = (suffix = '') => `.view-components-boxes-BoxEmailForm${suffix}`;

up.compiler(dcn(), ($el) => {
    formSubmitHandler({
        handler: $el.dataset.handler,
        $form: $(dcn('-form'), $el),
        $inputs: $$(dcn('-input'), $el),
        $submit: $(dcn('-submit'), $el),
        $message: $(dcn('-message'), $el),
        options: JSON.parse($el.dataset.options),
    });
});
