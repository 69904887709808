import { compiler } from 'unpoly';

compiler('.js-iframe-auto-height', (iframe) => {
    const updateHeight = () => {
        iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
    };

    const resizeObserver = new ResizeObserver(updateHeight);

    resizeObserver.observe(iframe);

    const mutationObserver = new MutationObserver(updateHeight);

    mutationObserver.observe(iframe.contentWindow.document, {
        attributes: true,
        childList: true,
        subtree: true,
    });

    iframe.addEventListener('DOMContentLoaded', updateHeight);
    iframe.addEventListener('load', updateHeight);
    window.addEventListener('load', updateHeight);
    window.addEventListener('resize', updateHeight);

    updateHeight();

    return () => {
        resizeObserver.disconnect();

        mutationObserver.disconnect();

        iframe.removeEventListener('DOMContentLoaded', updateHeight);
        iframe.removeEventListener('load', updateHeight);
        window.removeEventListener('load', updateHeight);
        window.removeEventListener('resize', updateHeight);
    };
});
