import up from 'unpoly';
import { $, $$ } from '../../../utils/dom';
import formSubmitHandler from '../../form-submit-handlers';

const dcn = (suffix = '') => `.view-blocks-PardotFormSolutions2Block${suffix}`;

up.compiler(dcn('-form'), ($el) => {
    formSubmitHandler({
        handler: 'pardot',
        $form: $el,
        $inputs: $$(dcn('-input'), $el),
        $submit: $(dcn('-submit'), $el),
        $message: $(dcn('-message'), $el),
        options: JSON.parse($el.dataset.settings),
    });
});
