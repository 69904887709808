export async function getS3Params(applicationId, group, file) {
    const url = '/api/application-files/params';

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            application: applicationId,
            group,
            filename: file.name,
            type: file.type,
            size: file.size,
        }),
    });

    let responseData = null;
    try {
        responseData = await response.json();
    } catch (error) {
        throw new Error('Failed to get S3 upload parameters.');
    }

    if (!response.ok || !responseData) {
        if (responseData) {
            throw new Error(responseData.error);
        } else {
            throw new Error('Failed to get S3 upload parameters.');
        }
    }

    return responseData;
}

export async function uploadFile({
    file,
    method,
    url,
    headers,
    abortSignal,
    onProgress,
}) {
    const xhr = new XMLHttpRequest();

    const handleAbort = () => {
        xhr.abort();
    };

    abortSignal?.addEventListener('abort', handleAbort);

    return new Promise((resolve, reject) => {
        xhr.responseType = 'blob';

        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4 || abortSignal.aborted) {
                return;
            }

            abortSignal?.removeEventListener('abort', handleAbort);

            if (xhr.status < 200 || xhr.status > 200) {
                const err = new Error(xhr.response.statusText);
                err.xhr = xhr;

                reject(err);

                return;
            }

            const response = new Response(xhr.response, {
                url: xhr.responseURL,
                status: xhr.status,
                statusText: xhr.statusText,
            });

            resolve(response);
        };

        xhr.addEventListener('error', () => {
            reject(new TypeError('Failed to fetch'));
        });

        xhr.upload.addEventListener('progress', (event) => {
            onProgress?.(event.loaded / event.total);
        });

        xhr.open(method, url, true);

        Object.entries(headers).forEach(([name, value]) => {
            xhr.setRequestHeader(name, value);
        });

        xhr.send(file);
    });
}

export async function updateFile(key, newProps) {
    const url = `/api/application-files/update`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ key, ...newProps }),
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
}

export async function deleteFile(key) {
    const url = `/api/application-files/delete`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ key }),
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
}

export async function getDownloadZipUrl(urls, filename) {
    const url = `/api/application-files/zip`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ urls, filename }),
    });

    if (!response.ok) {
        throw new Error(response.status);
    }

    return response.text();
}
