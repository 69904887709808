/**
 * Based on https://github.com/whatwg/html/issues/7732#issuecomment-2330373381.
 */

let isLocked = false;

function toggleBodyLock(lock) {
    if (lock === isLocked) {
        return;
    }

    const { documentElement, body } = document;

    if (lock) {
        body.style.paddingRight = `${window.innerWidth - documentElement.clientWidth}px`;
        body.style.top = `-${window.scrollY}px`;
        body.style.left = `-${window.scrollX}px`;
        body.style.right = 0;
        body.style.position = 'fixed';
    } else {
        const currentScrollY = parseInt(body.style.top || '0', 10) * -1;
        const currentScrollX = parseInt(body.style.left || '0', 10) * -1;

        body.style.paddingRight = '';
        body.style.position = '';
        body.style.top = '';
        body.style.left = '';
        body.style.right = '';

        window.scrollTo({
            left: currentScrollX,
            top: currentScrollY,
            behavior: 'instant',
        });
    }

    isLocked = lock;
}

export default toggleBodyLock;
