/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */

import Alpine from 'alpinejs';

import {
    deleteFile,
    getDownloadZipUrl,
    updateFile,
} from '../../../../../utils/application-files';

const applicationFilesTemplate = ({
    groupOptions,
    files,
    zipFilename,
    downloadZipUrl,
}) => ({
    groupOptions,
    files,
    isDownloadZipUrlDisabled: false,
    downloadZipUrl,

    init() {
        this.$watch('files', this.updateDownloadZipUrl.bind(this));
    },

    async setFileGroup(file, group) {
        const oldGroup = file.group;

        try {
            await updateFile(file.key, { group });
        } catch (err) {
            alert(err.message);

            this.files.find((f) => f.key === file.key).group = oldGroup;
        }
    },

    async deleteFile(file) {
        const confirmed = confirm(
            'Vill du verkligen radera den här filen?\n\nDetta går inte att ångra',
        );

        if (!confirmed) {
            return;
        }

        try {
            await deleteFile(file.key);

            this.files = this.files.filter((f) => f.key !== file.key);
        } catch (err) {
            alert(err.message);
        }
    },

    async updateDownloadZipUrl() {
        if (this.files.length === 0) {
            this.downloadZipUrl = null;

            return;
        }

        try {
            this.isDownloadZipUrlDisabled = true;

            this.downloadZipUrl = await getDownloadZipUrl(
                files.map((f) => f.url),
                zipFilename,
            );
        } catch (err) {
            alert(err.message);

            this.downloadZipUrl = null;
        } finally {
            this.isDownloadZipUrlDisabled = false;
        }
    },
});

Alpine.data(
    'app_view_components_templates_applications_ApplicationFilesTemplate',
    applicationFilesTemplate,
);
