const { location } = window;

window.dataLayer = window.dataLayer || [];

export const trackingUrlParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'ireferrer',
    'lreferrer',
];

export const sendGaEvent = ({ category, action, label, value }) => {
    window.ga?.getAll()[0]?.tracker?.send('event', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
    });
};

export const sendGtmEvent = ({ event, name, value }) => {
    window.dataLayer.push({
        event,
        name,
        value,
    });
};

export const sendEvent = ({ event, name, value }) => {
    sendGaEvent({
        category: event,
        action: name,
        label: value,
    });

    sendGtmEvent({
        event,
        name,
        value,
    });
};

const enableImboxTracking = () => {
    // eslint-disable-next-line no-underscore-dangle
    window._imbox = window._imbox || [];

    // eslint-disable-next-line no-underscore-dangle
    window._imbox.push([
        'onToggle',
        ({ isOpen }) => {
            const event = isOpen ? 'imbox-opened' : 'imbox-closed';

            sendEvent({ event });
        },
    ]);
};

const saveUtmParams = () => {
    const urlParams = new URLSearchParams(location.search);
    const urlKeys = Array.from(urlParams.keys());

    const hasParams = urlKeys.some((urlKey) =>
        trackingUrlParams.includes(urlKey.toLowerCase()),
    );

    if (!hasParams) {
        return;
    }

    trackingUrlParams.forEach((trackingKey) => {
        const urlKey = urlKeys.find((key) => key.toLowerCase() === trackingKey);

        const value = (urlKey && urlParams.get(urlKey)) || '';

        localStorage.setItem(`berghs_${trackingKey}`, value);
    });
};

export const fillUtmParams = (form) => {
    trackingUrlParams.forEach((name) => {
        const input = form.elements[name];

        if (!input) {
            return;
        }

        const value = localStorage.getItem(`berghs_${name}`);

        input.value = value;
    });
};

enableImboxTracking();
saveUtmParams();
