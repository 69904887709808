import Alpine from 'alpinejs';

const DEBOUNCE_INTERVAL = 1000;

function getFormData($form) {
    const formData = new FormData($form);

    const result = {};

    Array.from(formData.entries()).forEach(([key, value]) => {
        const keys = key.match(/[^[\]]+/g);
        let current = result;

        for (let i = 0; i < keys.length; i += 1) {
            const subkey = keys[i];
            const isLast = i === keys.length - 1;

            if (isLast) {
                if (current[subkey] && Array.isArray(current[subkey])) {
                    current[subkey].push(value);
                } else if (current[subkey]) {
                    current[subkey] = [current[subkey], value];
                } else {
                    current[subkey] = value;
                }
            } else {
                if (!current[subkey]) {
                    const nextKey = keys[i + 1];
                    current[subkey] =
                        nextKey === '' || !Number.isNaN(nextKey) ? [] : {};
                }
                current = current[subkey];
            }
        }
    });

    return result;
}

function toSimpleValidationErrors(errors) {
    const simpleErrors = {};

    Object.entries(errors).forEach(([key, val]) => {
        simpleErrors[key] = Array.isArray(val) ? val[0] : val;
    });

    return simpleErrors;
}

async function validate($form, validateOnly, signal) {
    const url = $form.action || window.location.toString();
    const method = $form.method || 'POST';
    const headers = {
        Accept: 'application/json, text/plain, */*',
        Precognition: true,
    };
    if (validateOnly?.size > 0) {
        headers['Precognition-Validate-Only'] = Array.from(validateOnly).join();
    }
    const formData = new FormData($form);

    const response = await fetch(url, {
        method,
        headers,
        body: formData,
        signal,
    });

    if (response.status >= 200 && response.status <= 299) {
        return {};
    }

    if (response.status !== 422) {
        const err = new Error(response.statusText);
        err.response = response;

        throw err;
    }

    const responseBody = await response.json();

    return toSimpleValidationErrors(responseBody.errors);
}

function createForm($form) {
    let timeout = null;
    let abortController = null;
    let promises = [];

    const form = Alpine.reactive({
        data: getFormData($form),
        touched: new Set(),
        errors: {},

        touch(name) {
            form.touched?.add(name);

            return this;
        },
        touchAll() {
            form.touched = null;
        },

        validate() {
            if (abortController === null) {
                this.doValidate();
            } else {
                clearTimeout(timeout);
                abortController.abort();

                timeout = setTimeout(() => {
                    this.doValidate();
                }, DEBOUNCE_INTERVAL);
            }

            return new Promise((resolve, reject) => {
                promises.push({ resolve, reject });
            });
        },
        async doValidate() {
            abortController = new AbortController();

            try {
                form.errors = await validate(
                    $form,
                    form.touched,
                    abortController.signal,
                );

                promises.forEach(({ resolve }) => {
                    resolve();
                });

                timeout = null;
                abortController = null;
                promises = [];
            } catch (err) {
                if (err.name === 'AbortError') {
                    return;
                }

                promises.forEach(({ reject }) => {
                    reject(err);
                });

                timeout = null;
                abortController = null;
                promises = [];
            }
        },

        setErrors(errors) {
            form.errors = errors;

            return form;
        },
    });

    $form.addEventListener('input', () => {
        form.data = getFormData($form);
    });

    return form;
}

const precognitiveForm = ({ errors = {}, showGenericError = false }) => ({
    form: null,
    isDisabled: false,
    submitMiddleware: [],
    showGenericError,

    init() {
        this.form = createForm(this.$el);
        this.form.setErrors(errors);
    },

    addMiddleware(fn) {
        this.submitMiddleware.push(fn);
    },
    removeMiddleware(fn) {
        this.submitMiddleware = this.submitMiddleware.filter((m) => m !== fn);
    },

    async submit() {
        if (this.isDisabled) {
            return;
        }

        this.isDisabled = true;
        this.showGenericError = false;

        try {
            this.form.touchAll();

            this.isDisabled = false;
            await this.$nextTick();
            this.$nextTick(() => {
                this.disabled = true;
            });
            await this.form.validate();

            if (Object.keys(this.form.errors).length !== 0) {
                this.isDisabled = false;

                return;
            }

            // eslint-disable-next-line no-restricted-syntax
            for (const submitHandler of this.submitMiddleware) {
                // eslint-disable-next-line no-await-in-loop
                await submitHandler();
            }

            this.isDisabled = false;
            await this.$nextTick();
            this.$el.submit();
            this.isDisabled = true;
        } catch (err) {
            if (err?.status !== 422) {
                this.showGenericError = true;
                // eslint-disable-next-line no-console
                console.error(err);
            }

            this.isDisabled = false;
        }
    },
});

Alpine.data('app_view_components_forms_precognitive_Form', precognitiveForm);

export default precognitiveForm;
