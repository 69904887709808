/**
 * Set max-height to scrollHeight.
 *
 * This is useful for improving css max-height transitions.
 */
import up from 'unpoly';

const { MutationObserver } = window;

up.compiler('.js-max-height-scroll-height', (el) => {
    const update = () => {
        el.style.maxHeight = `${el.scrollHeight}px`;
    };

    const mutationObserver = new MutationObserver(update);
    mutationObserver.observe(el, {
        attributes: true,
        childList: true,
        subtree: true,
    });

    window.addEventListener('load', update);
    window.addEventListener('resize', update);

    update();

    return () => {
        mutationObserver.disconnect();

        window.removeEventListener('load', update);
        window.removeEventListener('resize', update);
    };
});
