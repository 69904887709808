import up from 'unpoly';

const cn = (suffix = '') => `view-templates-PageScreen${suffix || ''}`;
const dcn = (suffix = '') => `.${cn(suffix)}`;

const REFRESH_INTERVAL = 60 * 1000;
const RELOAD_INTERVAL = 60 * 6 * 1000;

up.compiler(dcn(), ($el) => {
    if (window.self !== window.top) {
        return;
    }

    const $html = document.documentElement;
    const $body = document.body;

    const { rotation } = $el.dataset;

    if (rotation === '90') {
        $html.style.transformOrigin = 'left top';
        $html.style.width = '100vh';
        $html.style.height = '100vw';
        $html.style.transform = 'translate(100vw) rotate(90deg)';
    } else if (rotation === '-90') {
        $html.style.transformOrigin = 'left top';
        $html.style.width = '100vh';
        $html.style.height = '100vw';
        $html.style.transform = 'translate(0, 100vh) rotate(-90deg)';
    }

    let currentRefresh = null;

    function refresh() {
        if (currentRefresh) {
            currentRefresh();
            currentRefresh = null;
        }

        const cleanups = [];

        const cleanup = () => {
            cleanups.forEach((c) => c());
        };

        const nextIframe = document.createElement('iframe');
        nextIframe.className =
            'opacity-0 absolute left-0 top-0 -z-1 w-full h-full border-0';
        nextIframe.setAttribute('src', `${window.location}?${cn()}=1`);

        const onIframeLoad = () => {
            for (let i = $body.childNodes.length - 1; i >= 0; i -= 1) {
                if ($body.childNodes[i] !== nextIframe) {
                    $body.removeChild($body.childNodes[i]);
                }
            }

            nextIframe.classList.remove('opacity-0');
            nextIframe.classList.remove('-z-1');

            cleanup();
        };

        const onIframeError = () => {
            cleanup();
        };

        nextIframe.addEventListener('load', onIframeLoad);
        nextIframe.addEventListener('error', onIframeError);

        cleanups.push(() => {
            nextIframe.removeEventListener('error', onIframeError);
            nextIframe.removeEventListener('load', onIframeLoad);
        });

        document.body.appendChild(nextIframe);

        currentRefresh = () => {
            cleanup();
        };
    }

    const loadTime = new Date();

    const handleRefreshInterval = () => {
        if (new Date() - loadTime < RELOAD_INTERVAL) {
            refresh();

            return;
        }

        window.location.reload();
    };

    setInterval(handleRefreshInterval, REFRESH_INTERVAL);

    refresh();
});
