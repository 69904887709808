export default ({ email }, { course, nonce, errorMessage, successMessage }) => {
    const { ajaxUrl } = window.berghs;

    const data = new FormData();

    data.append('action', 'berghs/create_course_lead');
    data.append('nonce', nonce);
    data.append('course', course);
    data.append('email', email);

    return fetch(ajaxUrl, {
        method: 'POST',
        body: data,
    })
        .catch((fetchErr) => {
            const err = Error(errorMessage);
            err.error = fetchErr;

            throw err;
        })
        .then((response) => response.text().then((text) => [response, text]))
        .then(([response, responseText]) => {
            if (!response.ok) {
                const err = Error(errorMessage);

                err.statusCode = response.statusCode;
                err.responseText = responseText;

                throw err;
            }
        })
        .then(() => successMessage);
};
