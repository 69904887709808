const root = document.documentElement;

let oldRootWidth = parseInt(
    getComputedStyle(root).getPropertyValue('--app-layout-real100vw'),
    10,
);

const init = () => {
    const rootWidthValue = root.style.getPropertyValue(
        '--app-layout-real100vw',
    );

    if (rootWidthValue) {
        oldRootWidth = parseFloat(rootWidthValue.slice(0, -2));
    }
};

const measure = () => {
    const rootWidth = root.offsetWidth;

    if (oldRootWidth !== rootWidth) {
        root.style.setProperty('--app-layout-real100vw', `${rootWidth}px`);
        oldRootWidth = rootWidth;
    }
};

const onAnimationFrame = () => {
    measure();

    requestAnimationFrame(onAnimationFrame);
};

requestAnimationFrame(onAnimationFrame);

init();
